import axios from 'axios'
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在请求发送之前做一些事情
    // 统一设置token
    const token = localStorage.getItem("token")
    config.headers.Authorization = `Bearer ${token}`

    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 任何处于2xx范围内的状态码都会触发这个函数，对响应数据做一些处理
    const { token } = response.data
    token && localStorage.setItem("token",token)
    return response;
  }, function (error) {
    // 任何不在2xx范围内的状态码都会导致这个函数被触发，对响应错误进行处理
    const {status} = error.response
    if(status===401){
        localStorage.removeItem("token")
        window.location.href="#/login"
    }
    return Promise.reject(error);
  });
