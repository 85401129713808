<template>
  <div>
    <el-page-header
        content="首页"
        icon=""
        title="网站后台管理系统"
    />

    <el-card class="box-card">
      <el-row>
        <el-col :span="4">
          <el-avatar :size="100" :src="avatarUrl"/>
        </el-col>
        <el-col :span="20">
          <h3 style="line-height:100px;">欢迎 {{ store.state.userInfo.username }} 回来, {{ welcomeText }}</h3>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="box-card">
      <el-row>
        <el-col :span="8">
          <h4>用户：{{ store.state.userInfo.username }}</h4>
          <h4>角色：{{ roleText }}</h4>
        </el-col>

        <el-col :span="8">
          <h4>昵称：{{ store.state.userInfo.nickname }}</h4>
          <h4>注册时间：{{ store.state.userInfo.createdAt }}</h4>
        </el-col>

        <el-col :span="8">
          <h4>登录次数：{{ store.state.userInfo.logins }}</h4>
          <h4>最近登录时间：{{ store.state.userInfo.updatedAt }}</h4>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {computed, ref} from 'vue'

const store = useStore();
// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const avatarUrl = computed(
    () =>
        store.state.userInfo.avatar
            ? baseUrl + store.state.userInfo.avatar
            : `https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png`
);

const welcomeText = computed(() => new Date().getHours() < 18 ? "认认真真干活。" : "休息好才能工作好。");
let roleText = "";
store.state.userInfo.role === 1 ? roleText = '管理员' : roleText = '编辑';
</script>

<style lang="scss" scoped>
.box-card {
  margin-top: 50px;
}

.el-carousel__item h3 {
  color: white;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

h4 {
  height: 50px;
  line-height: 50px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
