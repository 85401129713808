<template>
  <router-view /> <!-- 渲染当前路由对应的组件 -->
</template>

<style lang="scss">
// 全局样式
body {
  background-color: #f8f8f8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

a {
  text-decoration: none; /* 取消下划线 */
  color: inherit; /* 继承父元素的颜色 */
}

// 滚动调样式
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background: #1890ff;
}

::-webkit-scrollbar-track {
  background: #ddd;
}
</style>
